let isRedirected: boolean = false;

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) {
    return;
  }

  if (isRedirected) {
    isRedirected = false;
    return;
  }

  const { $i18n, $pinia } = useNuxtApp();

  const getRouteBaseName = useRouteBaseName();
  const localeRoute = useLocaleRoute();
  const searchStore = useSearchStore($pinia);

  const routeBaseName = getRouteBaseName(to);

  const query = { ...to.query };
  const params = { ...to.params };
  const originalQuery = { ...to.query };

  const isCitySearch = routeBaseName === RBN_CITY_SEARCH;
  const isCountrySearch = routeBaseName === RBN_COUNTRY_SEARCH;
  const isFederalState = routeBaseName === RBN_FEDERAL_STATE_SEARCH;
  const isRegionSearch = routeBaseName === RBN_REGION_SEARCH;

  if (isCountrySearch) {
    query.country = params.countrySlug;
  }
  if (isCitySearch) {
    query.city = params.citySlug;
  }
  if (isFederalState) {
    query.federal_state = params.federalStateSlug;
  }
  if (isRegionSearch) {
    query.region = params.regionSlug;
  }

  // if query contains federal_state and country, we need to use just federal_state
  if (query.country && query.federal_state) {
    delete query.country;
  }

  /*
  #######################################
  ### t = tracking mode               ###
  #######################################
  TRACKING_MODE_NORMAL = '0'
  TRACKING_MODE_ONLY_TRACKING = '1'
  TRACKING_MODE_NO_TRACKING = '2'
  */
  query.t = '2';

  const response = await searchStore.getSearchResults({ query, lang: $i18n.locale.value });

  const transformedSearchResponse = {};

  if (response?.meta?.location?.name) {
    transformedSearchResponse.distance = response.meta.distance || null;
    transformedSearchResponse.headline = response.meta.location.name;
    transformedSearchResponse.location = response.meta.location;
    transformedSearchResponse.slugs = response.meta.location.slugs;
  }

  if (response?.meta?.location?.country) {
    transformedSearchResponse.country = response.meta.location.country;
  }

  if (response?.meta?.location?.federal_state) {
    transformedSearchResponse.federal_state = response.meta.location.federal_state;
  }

  /***********************************
     * adjust search term params start  *
     * (for mixed translations)         *
     ***********************************/
  if (isCountrySearch || isCitySearch || isRegionSearch || isFederalState || transformedSearchResponse.slugs) {
    delete originalQuery.country;
    delete originalQuery.city;
    delete originalQuery.federal_state;
    delete originalQuery.region;
    let searchTerms = [];

    if (isRegionSearch) {
      searchTerms = [params.regionSlug];
    } else if (isCountrySearch) {
      searchTerms = [params.countrySlug];
    } else if (isCitySearch) {
      searchTerms = [params.citySlug];
    } else if (isFederalState) {
      searchTerms.push(params.countrySlug);
      searchTerms.push(params.federalStateSlug);
    }

    const paramOne = searchTerms?.[0];
    const paramTwo = searchTerms?.[1];
    const slugAtLang = transformedSearchResponse.slugs?.[$i18n.locale.value];
    const countrySlugAtLang = transformedSearchResponse.country?.slugs?.[$i18n.locale.value];

    if (isCountrySearch) {
      if (searchTerms.length === 1 && slugAtLang !== paramOne) {
        const newRoute = localeRoute({
          name: RBN_COUNTRY_SEARCH,
          params: {
            countrySlug: slugAtLang,
          },
          query: originalQuery,
        });
        if (newRoute?.fullPath) {
          isRedirected = true;
          return navigateTo(newRoute.fullPath, { redirectCode: 301 });
        }
      }
    } else if (isCitySearch) {
      if (searchTerms.length === 1 && slugAtLang !== paramOne) {
        const newRoute = localeRoute({
          name: RBN_CITY_SEARCH,
          params: {
            citySlug: slugAtLang,
          },
          query: originalQuery,
        });
        if (newRoute?.path) {
          return navigateTo(newRoute.fullPath, { redirectCode: 301 });
        }
      }
    } else if (isFederalState) {
      if (searchTerms.length === 2 && (countrySlugAtLang !== paramOne || slugAtLang !== paramTwo)) {
        const newRoute = localeRoute({
          name: RBN_FEDERAL_STATE_SEARCH,
          params: {
            countrySlug: countrySlugAtLang,
            federalStateSlug: slugAtLang,
          },
          query: originalQuery,
        });
        if (newRoute?.path) {
          return navigateTo(newRoute.fullPath, { redirectCode: 301 });
        }
      }
    } else if (isRegionSearch) {
      if (searchTerms.length === 1 && slugAtLang !== paramOne) {
        const newRoute = localeRoute({
          name: RBN_REGION_SEARCH,
          params: { regionSlug: slugAtLang },
          query: originalQuery,
        });
        if (newRoute?.path) {
          return navigateTo(newRoute.fullPath, { redirectCode: 301 });
        }
      }
    }
  }

  return;
});
